<script setup lang="ts">
import type { EditorialValuesProps } from './EditorialValues.props'

defineProps<EditorialValuesProps>()

const logoSizeOptions = {
  s: 'w-full md:w-60',
  m: 'w-full md:w-80',
  l: 'w-full md:w-100',
}
</script>

<template>
  <div>
    <div
      class="relative w-full"
      :class="[
        { 'editorial-values-homepage': isHome },
        backgroundMedia?.aspectRatio
          ? `aspect-${backgroundMedia?.aspectRatio}`
          : 'aspect-1/1 lg:aspect-16/9',
      ]"
    >
      <!-- Media container -->
      <div class="media-container absolute inset-0">
        <UIMedia
          class="h-full w-full object-cover object-top"
          v-bind="backgroundMedia"
        />
      </div>

      <!-- Gradient Overlay -->
      <div
        v-if="showOverlay"
        class="overlay-container bg-neutral-black absolute inset-0"
        :class="[{ [`bg-opacity-${overlayOpacity}`]: overlayOpacity }]"
      />

      <!-- Inner container -->
      <div
        class="inner-container relative flex-col items-center justify-center gap-12 p-4 md:absolute md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2"
        :class="[isHome ? 'hidden landscape:flex' : 'flex']"
      >
        <NuxtPicture
          v-if="logo && logo.media.type === 'image' && logo.media.srcImage"
          class="mx-auto block"
          :alt="logo.media.alt ?? ''"
          provider="cloudinary"
          :src="
            logo.media.srcImage.secure_url
              ? logo.media.srcImage.secure_url!
              : logo.media.srcImage.public_id
          "
          format="svg"
          :img-attrs="{
            class: 'w-full h-auto',
          }"
          :class="{
            [logoSizeOptions[logoSize!]]: logoSize,
          }"
        />

        <span
          v-if="text"
          class="text-light-5 text-center"
          :class="`text-editorial-${textColor}`"
        >
          <UtilsMarkdown :content="text" />
        </span>

        <UILink
          v-if="cta && cta.label"
          anatomy="primary"
          v-bind="cta"
          :background-color="cta.backgroundColor ?? 'black'"
          :label-color="cta.labelColor ?? 'white'"
        />
      </div>
    </div>

    <!-- Homepage mobile banner -->
    <div
      v-if="isHome && firstLabel && firstLabel.length > 0"
      class="landscape:hidden"
    >
      <HomepageCardBannerLogo :logo="logoHomepageCard" />
      <HomepageCardBannerLabel
        :first-label="firstLabel?.[0]"
        :second-label="secondLabel?.[0]"
        class="justify-center"
      />
    </div>
  </div>
</template>
